<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Parameters') }}
    </PageTitle>
    <Loader v-if="configurationDataLoading" class="h-050screen"/>
    <t-card  v-else class="w-full">
      <div class="flex flex-col justify-evenly lg:justify-between w-full gap-3" v-if="configData">
        <div class="flex flex-row gap-3 bg-grayedLight px-2 py-1 text-xs font-semibold uppercase">
          <div class="w-1/4">{{ trans('LABEL:Parameter') }}</div>
          <div class="w-1/4">{{ trans('LABEL:Value') }}</div>
          <div class="w-1/2">{{ trans('LABEL:Meaning') }}</div>
        </div>
        <div class="flex flex-row gap-3 items-center" v-for="(config, index) in configData" :key="config.key">
          <template v-if="config.key !== 'picture_placeholder_thumbnail' && config.key !== 'picture_placeholder'">
            <div class="w-1/4 text-s">{{ config.key }}</div>
            <TextField class="w-1/4 text-s" v-model="config.value"/>
            <div class="w-1/2  text-s">{{ config.description }}</div>
          </template>
          <template v-else>
            <template v-if="config.key === 'picture_placeholder'">
              <div class="w-1/4 text-xs">{{ trans('LABEL:PicturePlaceholder') }}</div>
              <div class="w-1/4">
                <img class="w-full h-48 border border-grayedHR object-cover rounded" :src="placeholders.picture_placeholder" />
              </div>
              <div class="w-1/2">
                <input v-on:change="addPlaceholderImage($event, 'picture_placeholder')" type="file" />
              </div>
            </template>
            <template v-if="config.key === 'picture_placeholder_thumbnail'">
              <div class="w-1/4 text-xs">{{ trans('LABEL:PicturePlaceholderThumbnail') }}</div>
              <div class="w-1/4">
                <img class="w-full h-48 border border-grayedHR object-cover rounded" :src="placeholders.picture_placeholder_thumbnail" />
              </div>
              <div class="w-1/2">
                <input v-on:change="addPlaceholderImage($event, 'picture_placeholder_thumbnail')" type="file" />
              </div>
            </template>
          </template>
        </div>
        <div class="flex flex-row col-span-3 justify-end">
          <Button @clicked="saveConfig" :text="trans('BUTTON:Save')" />
        </div>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame.vue";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper.vue";
import Loader from "@/views/Loader";
import PageTitle from "@/layouts/components/pages/PageTitle.vue";
import TextField from "@/utils/crud/components/field-types/Text.vue";

import api from "@/common/services/api";
import {mapActions} from "vuex";

export default {
	name: "AdminConfiguration",
	components: {
    AdminPageFrame,
    Button,
    DataTable,
    FieldWrapper,
    Loader,
    PageTitle,
    TextField,
  },


	data() {
		return {
      configData: {
			},

			placeholders: {
        picture_placeholder: null,
        picture_placeholder_thumbnail: null,
			},
      configurationDataLoading: true,
		}
	},
	methods: {
		...mapActions('alert', ['success', 'error']),

		addPlaceholderImage(event, type) {
			let placeholder = this.configData.find(el => el.key === type);
			this.$set(placeholder, 'value', event.target.files[0])

			this.$set(this.placeholders, type, URL.createObjectURL(event.target.files[0]));
		},

		getConfig() {
      this.configurationDataLoading = true;
			api.setLocale();
			api.get(`/admin/configuration`)
					.then(
							success => {
								this.configData = structuredClone(success.data.config);

								this.placeholders.picture_placeholder = this.configData.find(el => el.key === 'picture_placeholder').value
								this.placeholders.picture_placeholder_thumbnail = this.configData.find(el => el.key === 'picture_placeholder_thumbnail').value

								this.configData.find(el => el.key === 'picture_placeholder').value = null;
								this.configData.find(el => el.key === 'picture_placeholder_thumbnail').value = null;
							},
							error => {

							}
					)
					.finally(() => {
            this.configurationDataLoading = false;
					}
			);
		},
		saveConfig() {
			this.updateConfig();
		},
		updateConfig() {
			let data = new FormData();

			Object.keys(this.configData).forEach((key) => {
				if(this.configData[key].value !== null && this.configData[key].value !== undefined) {
					data.append(this.configData[key].key, this.configData[key].value);
				}
			})

			api.post(`/admin/configuration`, data)
					.then(
							success => {
								this.configData = structuredClone(success.data.config);
								this.success(success);
							},
							error => {
								this.success(error);
							}
					)
					.finally(() => {

							}
					);
		}
	},
	mounted() {

		this.getConfig();
	}
}
</script>

<style scoped lang="scss">

</style>